import React from 'react'
import config from '../config.js'
import Guess from './Guess.js'
import Numpad from './Numpad.js'
import PopMessage from './PopMessage.js'
import EndGamePop from './EndGamePop.js'
import dayjs from 'dayjs'
import answer from '../util/answer.js'

class Game extends React.Component {
  constructor (props) {
    super(props);

    this.today = dayjs();
    var loadData = false;
    var saveData = window.localStorage.getItem('saveData');
    
    if (this.props.mode === 'daily') {
      try {
        if (saveData) {
          saveData = JSON.parse(saveData);
          if (saveData.date === this.today.format('YYYY-MM-DD')) {
            loadData = true;
          }
          else {
            console.log('date doesnt match');
          }
        }
      } catch (e) {
        console.log('error loading data');
        console.log(e);
      }
    }

    if (loadData) {
      this.state = saveData.state;
    }
    else {
      this.state = this.newGameState();
    }
    
    var stats = window.localStorage.getItem('stats');
    stats = stats ? JSON.parse(stats) : {
      daily: {
        wins: 0,
        losses: 0,
        streak: 0,
        dist: [0, 0, 0, 0, 0]
      }
    };
    this.state['stats'] = stats;

    this.onFullscreenChange = this.onFullscreenChange.bind(this);
    this.getUpdatedStats = this.getUpdatedStats.bind(this);
    this.newGameState = this.newGameState.bind(this);
    this.startNewGame = this.startNewGame.bind(this);
    this.endPopClose = this.endPopClose.bind(this);
    this.showError = this.showError.bind(this);
    this.compareAnswer = this.compareAnswer.bind(this);
    this.makeGuess = this.makeGuess.bind(this);
    this.deleteDigit = this.deleteDigit.bind(this);
    this.enterDigit = this.enterDigit.bind(this);
    this.onNumpadEmit = this.onNumpadEmit.bind(this);
  }

  componentDidMount () {
    window.addEventListener('fullscreenchange', this.onFullscreenChange);

    try {
      document.documentElement.requestFullscreen();
    }
    catch (e) {
      console.log('fullscreen not supported');
    }
  }

  componentWillUnmount () {
    window.removeEventListener('fullscreenchange', this.onFullscreenChange);

    try {
      document.exitFullscreen();
    }
    catch (e) {
      console.log('fullscreen not supported');
    }
  }

  onFullscreenChange () {
    if (document.fullscreenElement === null) {
      this.props.goBack();
    }
  }

  newGameState () {
    var guesses = [];
    for (var i = 0; i < config.numGuesses; i++) {
      var guess = { guessed: false, digits: [] }
      for (var j = 0; j < config.numDigits; j++) {
        guess.digits.push('');
      }
      guesses.push(guess);
    }

    return {
      gameStatus: 'playing',
      guesses: guesses,
      currentGuess: 0,
      currentDigit: 0,
      answer: this.props.mode === 'daily' ? answer.getAnswer(this.today) : answer.getAnswer(),
      digitStatus: ['', '', '', '', '', '', '', '', '', ''],
      errorMessage: '',
      errorShowing: false,
      endPopVisible: false,
    };
  }

  startNewGame () {
    this.setState(this.newGameState());
  }

  getUpdatedStats (oldStats, winGuessIndex, isWin) {
    var dailyStats = {...(oldStats.daily)};
    var dist = [...(dailyStats.dist)];

    if (isWin) {
      dailyStats.streak++;
      dailyStats.wins++;
      dist[winGuessIndex]++;
      dailyStats.dist = dist;
    }
    else {
      dailyStats.streak = 0;
      dailyStats.losses++;
    }

    return {
      daily: dailyStats
    };
  }

  endPopClose () {
    this.setState({
      endPopVisible: false
    });
  }

  showError (msg) {
    this.setState({
      errorMessage: msg,
      errorShowing: true
    }, () => {
      setTimeout(() => {
        this.setState({
          errorShowing: false
        })
      }, 2400);
    });
  }

  compareAnswer () {
    var guess = '';
    for (var i = 0; i < this.state.guesses[this.state.currentGuess].digits.length; i++) {
      guess += ('' + this.state.guesses[this.state.currentGuess].digits[i]);
    }

    if (this.state.answer === guess) { return 0; }
    else if (this.state.answer < guess) { return -1; }
    else if (this.state.answer > guess) { return 1; }
  }

  makeGuess () {
    if (this.state.currentDigit === config.numDigits) {
      this.setState(prevState => {
        var guessesCopy = [...prevState.guesses];
        guessesCopy[prevState.currentGuess].guessed = true;

        // update digitStatus for numpad colors
        var digitStatusCopy = [...prevState.digitStatus];
        guessesCopy[prevState.currentGuess].digits.forEach((digit, i) => {
          if (digitStatusCopy[digit] !== 'correct') {
            if (digit === prevState.answer.charAt(i)) {
              digitStatusCopy[digit] = 'correct';
            }
            else if (prevState.answer.includes(digit)) {
              digitStatusCopy[digit] = 'exists';
            }
            else {
              digitStatusCopy[digit] = 'wrong';
            }
          }
        });
      
        var gameStatus = 'playing';
        var endPopVisible = false;
        var stats = {...(prevState.stats)};

        if (this.compareAnswer() === 0) {
          gameStatus = 'victory';
          endPopVisible = true;

          if (this.props.mode === 'daily') {
            stats = this.getUpdatedStats(stats, prevState.currentGuess, true);
            window.localStorage.setItem('stats', JSON.stringify(stats));
          }
        }
        else if (this.state.currentGuess === config.numGuesses - 1) {
          gameStatus = 'defeat';
          endPopVisible = true;

          if (this.props.mode === 'daily') {
            stats = this.getUpdatedStats(stats, prevState.currentGuess, false);
            window.localStorage.setItem('stats', JSON.stringify(stats));
          }
        }

        return {
          guesses: guessesCopy,
          currentGuess: prevState.currentGuess + 1,
          currentDigit: 0,
          digitStatus: digitStatusCopy,
          gameStatus: gameStatus,
          endPopVisible: endPopVisible,
          stats: stats
        };
      }, () => {
        if (this.props.mode === 'daily') {
          window.localStorage.setItem('saveData', JSON.stringify({
            date: this.today.format('YYYY-MM-DD'),
            state: this.state
          }));
        }
      });
    }
    else {
      this.showError('Not enough digits');
    }
  }

  deleteDigit () {
    if (this.state.currentDigit > 0) {
      this.setState(prevState => {
        var guessesCopy = [...prevState.guesses];
        guessesCopy[prevState.currentGuess].digits[prevState.currentDigit - 1] = '';
        
        return {
          guesses: guessesCopy,
          currentDigit: prevState.currentDigit - 1
        };
      });
    }
  }

  enterDigit (digit) {
    if (this.state.currentDigit < config.numDigits) {
      this.setState(prevState => {
        var guessesCopy = [...prevState.guesses];
        guessesCopy[prevState.currentGuess].digits[prevState.currentDigit] = digit;
        
        return {
          guesses: guessesCopy,
          currentDigit: prevState.currentDigit + 1
        };
      });
    }
  }

  onNumpadEmit (value) {
    if (this.state.gameStatus === 'playing') {
      if (value === 'Enter') {
        this.makeGuess();
      }
      else if (value === 'Backspace') {
        this.deleteDigit();
      }
      else {
        this.enterDigit(value);
      }
    }
    else if (['victory', 'defeat'].includes(this.state.gameStatus)) {
      if (value === 'Enter') {
        this.setState({
          endPopVisible: true
        });
      }
    }
  }

  render () {
    return (
      <div className="Game">
        <PopMessage message={this.state.errorMessage} visible={this.state.errorShowing}/>
        <EndGamePop
          mode={this.props.mode}
          victory={this.state.gameStatus === 'victory'}
          answer={this.state.answer}
          guesses={this.state.guesses}
          stats={this.state.stats}
          visible={this.state.endPopVisible}
          onClose={this.endPopClose}
          onPlayAgain={this.startNewGame}
        />
        <div className="gameBody">
          <div className="guessGrid">
            {this.state.guesses.map((guess, i) => (
              <Guess key={i} guessed={guess.guessed} digits={guess.digits} answer={this.state.answer}/>
            ))}
          </div>
          <Numpad onEmit={this.onNumpadEmit} digitStatus={this.state.digitStatus}/>
        </div>
      </div>
    );
  }
}

export default Game;
