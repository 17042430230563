import React from 'react'

class Digit extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      hidden: true
    };
  }

  componentDidMount () {
    var timeout = Math.random() * 600;
    setTimeout(() => {
      this.setState({
        hidden: false
      });
    }, timeout);
  }

  render () {
    return (
      <div className={'Digit ' +
        (this.state.hidden ? 'hidden ' : '') +
        this.props.status
      }>
        <div className={'inner ' +
          (this.props.number === ''
            ? 'hidden'
            : ''
          )
        }>
          {this.props.number}
        </div>
      </div>
    );
  }
}

export default Digit;
