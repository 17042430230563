import React from 'react'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import SummaryImage from './SummaryImage.js'
import StatBars from './StatBars.js'
import PopMessage from './PopMessage.js'

dayjs.extend(duration);

class EndGamePop extends React.Component {
  constructor (props) {
    super(props);

    this.blob = null;
    this.interval = null;
    this.endOfDay = dayjs().endOf('day');

    this.state = {
      errorMessage: '',
      errorShowing: false,
      timeUntilNext: ''
    };

    this.getWinPercentage = this.getWinPercentage.bind(this);
    this.updateTimer = this.updateTimer.bind(this);
    this.close = this.close.bind(this);
    this.share = this.share.bind(this);
    this.setBlob = this.setBlob.bind(this);
    this.showError = this.showError.bind(this);
  }

  componentDidMount () {
    this.interval = setInterval(() => this.updateTimer(), 1000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }

  getWinPercentage () {
    var percentage = (this.props.stats.daily.wins + this.props.stats.daily.losses) === 0
      ? 0
      : this.props.stats.daily.wins /
        (this.props.stats.daily.wins + this.props.stats.daily.losses);

    return percentage.toFixed(2) * 100 + '%';
  }

  updateTimer () {
    this.setState({
      timeUntilNext: dayjs.duration(this.endOfDay.diff(dayjs())).format('HH:mm:ss')
    });
  }

  close () {
    this.props.onClose();
  }

  share () {
    if (this.blob) {
      (async () => {
        try {
          await navigator.share({
            files: [
              new File(
                [this.blob],
                'compardle.png',
                {
                  type: this.blob.type
                }
              )
            ],
            title: 'Compardle',
            text: 'My Compardle score today - https://compardle.httpanda.com'
          });
        }
        catch (e) {
          this.showError('Error sharing - try downloading the image instead');
        }
      })();
    }
  }

  setBlob (blob) {
    this.blob = blob;
  }

  showError (msg) {
    this.setState({
      errorMessage: msg,
      errorShowing: true
    }, () => {
      setTimeout(() => {
        this.setState({
          errorShowing: false
        })
      }, 2400);
    });
  }

  render () {
    return (
      <div className={'EndGamePop ' +
        (this.props.visible
          ? ''
          : 'hidden'
        )
      }>
        <div className="popBox">
          {this.props.victory &&
            <div className="title victory">Huzzah!</div>
          }
          {!this.props.victory &&
            <div className="title defeat">Too bad...</div>
          }
          <div className="body">
            <PopMessage message={this.state.errorMessage} visible={this.state.errorShowing}/>
            {this.props.victory &&
              <p className="textCenter">
                You have successfully guessed the correct number. Great job!
              </p>
            }
            {!this.props.victory &&
              <p className="textCenter">
                Sorry, better luck next time. The number was <span className="bold">{this.props.answer}</span>.
              </p>
            }
            <div className={
              'summaryStats ' + (this.props.mode === 'daily' ? 'daily' : '')
            }>
              {this.props.visible &&
                <SummaryImage
                  answer={this.props.answer}
                  guesses={this.props.guesses}
                  onBlob={this.setBlob}
                />
              }
              {this.props.mode === 'daily' &&
                <StatBars values={this.props.stats.daily.dist}/>
              }
            </div>
            {this.props.mode === 'daily' &&
              <div>
                <div className="statsBox">
                  <div>
                    <div>
                      Wins:
                      <div className="stat">{this.props.stats.daily.wins}</div>
                      Losses:
                      <div className="stat">{this.props.stats.daily.losses}</div>
                    </div>
                    <div>
                      Win Percentage:
                      <div className="stat">{this.getWinPercentage()}</div>
                    </div>
                  </div>
                </div>
                <div className={
                  'timeleftBox ' + (this.state.timeUntilNext === '' ? 'hidden' : '')
                }>
                  <div>Next puzzle:</div>
                  <div className="timeleft">{this.state.timeUntilNext}</div>
                </div>
              </div>
            }
          </div>
          {this.props.mode === 'freeplay' &&
            <div className="buttons short">
              <div className="playAgain" onClick={this.props.onPlayAgain}>Play Again</div>
            </div>
          }
          <div className="buttons">
            <div className="close" onClick={this.close}>Close</div>
            <div className="share" onClick={this.share}>Share</div>
          </div>
        </div>
      </div>
    );
  }
}

export default EndGamePop;
