import React from 'react'
import './App.css'
import fbase from './util/fbase.js'
import httpandaLogo from './img/HTTPandaLogo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleQuestion, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons'
import InfoPop from './cmp/InfoPop.js'
import Game from './cmp/Game.js'

class App extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      mode: '', // daily, freeplay
      howToPlayVisible: !window.localStorage.getItem('isReturningUser'),
      buttonsHidden: true
    };

    this.setMode = this.setMode.bind(this);
    this.howToPlayClose = this.howToPlayClose.bind(this);
    this.howToPlayOpen = this.howToPlayOpen.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount () {
    setTimeout(() => {
      this.setState({
        buttonsHidden: false
      });
    }, 100);
  }

  setMode (mode) {
    this.setState({
      mode: mode
    });
  }

  howToPlayClose () {
    window.localStorage.setItem('isReturningUser', true);
    this.setState({
      howToPlayVisible: false
    });
  }

  howToPlayOpen () {
    this.setState({
      howToPlayVisible: true
    });
  }

  goBack () {
    this.setState({
      mode: ''
    });
  }

  render () {
    return (
      <div className="App">
        <InfoPop visible={this.state.howToPlayVisible} onClose={this.howToPlayClose}/>
        <div className="gameTitle">
          <div className="back">Compardle</div>
          <div className="front">Compardle</div>
        </div>
        <div
          className={
            'backButton ' + (this.state.mode === '' ? 'hidden' : '')
          }
          onClick={() => this.goBack()}
        >
          <FontAwesomeIcon icon={faCircleArrowLeft}/>
        </div>
        <div className="helpButton" onClick={() => this.howToPlayOpen()}>
          <FontAwesomeIcon icon={faCircleQuestion}/>
        </div>
        {this.state.mode === '' &&
          <div>
            <div className="gamePicker">
              <div
                className={
                  'pickerButton ' + (this.state.buttonsHidden ? 'hidden' : '')
                }
                onClick={() => this.setMode('daily')}
              >
                Daily Puzzle
              </div>
              <div
                className={
                  'pickerButton ' + (this.state.buttonsHidden ? 'hidden' : '')
                }
                onClick={() => this.setMode('freeplay')}
              >
                Free Play
              </div>
            </div>
            <a href="https://httpanda.com" target="_blank" className="logoLink">
              <img src={httpandaLogo}/>
            </a>
          </div>
        }
        {this.state.mode !== '' &&
          <Game mode={this.state.mode} goBack={this.goBack}/>
        }
      </div>
    );
  }
}

export default App;
