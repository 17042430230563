import React from 'react'
import Digit from './Digit.js'

class InfoPop extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className={
        'InfoPop ' +
        (this.props.visible ? '' : 'hidden')
      }>
        <div className="title">How to Play</div>
        <div className="body">
          <div className="bodyDesc">
            You have 5 tries to guess a 4-digit
            number (0000-9999). After each guess, you may
            see the following indicators:
          </div>
          <div>
            <div className="infoRow">
              <div className="compareSign lt">&lt;</div>
              <div className="infoDesc">Answer is less than your guess</div>
            </div>
            <div className="infoRow">
              <div className="compareSign gt">&gt;</div>
              <div className="infoDesc">Answer is greater than your guess</div>
            </div>
            <div className="infoRow">
              <Digit number={4} status="wrong"/>
              <div className="infoDesc">Digit is not in the answer</div>
            </div>
            <div className="infoRow">
              <Digit number={5} status="exists"/>
              <div className="infoDesc">Digit is in the answer but not in the correct spot</div>
            </div>
            <div className="infoRow">
              <Digit number={6} status="correct"/>
              <div className="infoDesc">Digit is in the answer and in the correct spot</div>
            </div>
            <p>
              There is a new puzzle available at the beginning of each day. Good luck!
            </p>
          </div>
        </div>
        <div className="closeButton" onClick={this.props.onClose}>Got it!</div>
      </div>
    );
  }
}

export default InfoPop;
