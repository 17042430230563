import answers from '../data/answers.js'
import dayjs from 'dayjs'

var today = dayjs();

function getAnswer (date) {
  var answer;

  if (date) {
    var launchDate = dayjs('2022-03-04').format('YYYY-MM-DD');
    var answerIndex = date.diff(launchDate, 'day') % answers.length;
    answer =  answers[answerIndex];
  }
  else {
    answer = Math.trunc(Math.random() * 10) + '' +
             Math.trunc(Math.random() * 10) + '' +
             Math.trunc(Math.random() * 10) + '' +
             Math.trunc(Math.random() * 10) + '';
  }

  return answer;
}


export default {
  getAnswer
};
