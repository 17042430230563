import React from 'react'

class StatBars extends React.Component {
  constructor (props) {
    super(props);

    this.getMaxVal = this.getMaxVal.bind(this);
  }

  getMaxVal () {
    return Math.max(...this.props.values);
  }

  render () {
    return (
      <div className="StatBars">
        <div className="barsTitle">Guess Stats</div>
        <div>
          {this.props.values.map((val, i) => (
            <div key={i} className="statbar">
              <div>{i + 1}:</div>
              <div className="barBox">
                <div className="bar" style={{width: (val * 100 / this.getMaxVal() + 8) + '%'}}>
                  {val}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default StatBars;
