import React from 'react'
import Digit from './Digit.js'

class Guess extends React.Component {
  constructor (props) {
    super(props);

    this.getCompareSign = this.getCompareSign.bind(this);
    this.digitExists = this.digitExists.bind(this);
  }

  getCompareSign () {
    var guess = '';
    this.props.digits.forEach(digit => guess += digit);
    
    if (this.props.answer < guess) { return '<'; }
    if (this.props.answer > guess) { return '>'; }
    if (this.props.answer === guess) { return '='; }
  }

  digitExists (digit, digitIndex) {
    var exists = false;
    var numOccurrences = 0;
    var numCorrect = 0;
    var numExistsPrev = 0;

    if (digit !== '') {
      for (var i = 0; i < this.props.answer.length; i++) {
        if (digit === this.props.answer[i]) {
          numOccurrences++
        }

        if (digit === this.props.digits[i]) {
          if (digit === this.props.answer[i]) {
            numCorrect++;
          }
          else if (i < digitIndex) {
            numExistsPrev++;
          }
        }
      }

      if (numCorrect + numExistsPrev < numOccurrences) {
        exists = true;
      }
    }

    return exists;
  }

  render () {
    return (
      <div className={
        'Guess ' + (this.props.guessed ? 'guessed' : '')
      }>
        <div className={
          'compareSign ' +
            (this.getCompareSign() === '<'
              ? 'lt'
              : (this.getCompareSign() === '>'
                  ? 'gt'
                  : 'eq'
                )
            )
        }>
          {this.getCompareSign()}
        </div>
        {this.props.digits.map((digit, i) => (
          <Digit key={i} number={digit} status={
            (this.props.answer.charAt(i) == digit
              ? 'correct'
              : this.digitExists(digit, i)
                ? 'exists'
                : 'wrong')
          }/>
        ))}
      </div>
    );
  }
}

export default Guess;
