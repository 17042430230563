import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyDlOsadPY7hft3C_hiJMw84GM3yrG2XU2k",
  authDomain: "compardle.firebaseapp.com",
  projectId: "compardle",
  storageBucket: "compardle.appspot.com",
  messagingSenderId: "193875309029",
  appId: "1:193875309029:web:2014e01f5c18f5b131bf0c",
  measurementId: "G-CHDE8R337M"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default {
  app,
  analytics
};
