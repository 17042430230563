import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDeleteLeft, faArrowTurnDown } from '@fortawesome/free-solid-svg-icons'

class Numpad extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      numbers: ['1', '2', '3', '4', '5',
                '6', '7', '8', '9', '0',
                'Backspace', 'Enter']
    };

    this.onKeydown = this.onKeydown.bind(this);
  }

  componentDidMount () {
    document.addEventListener('keydown', e => this.onKeydown(e.key));
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', e => this.onKeydown(e.key));
  }

  onKeydown (key) {
    if (this.state.numbers.includes(key)) {
      this.props.onEmit(key);
    }
  }

  render () {
    return (
      <div className="Numpad">
        {this.state.numbers.map((num, i) => (
          <div key={i}>
            {num === 'Enter' &&
              <div className="numButton numWide enter" key={i} onClick={() => this.props.onEmit(num)}>
                <FontAwesomeIcon icon={faArrowTurnDown} rotation={90}/>
              </div>
            }
            {num === 'Backspace' &&
              <div className="numButton numWide backspace" key={i} type="button" onClick={() => this.props.onEmit(num)}>
                <FontAwesomeIcon icon={faDeleteLeft}/>
              </div>
            }
            {num !== 'Enter' && num !== 'Backspace' &&
              <div
                className={'numButton ' +
                  this.props.digitStatus[num]
                }
                key={i}
                type="button"
                onClick={() => this.props.onEmit(num)}>
                <div>{num}</div>
              </div>
            }
          </div>
        ))}
      </div>
    );
  }
}

export default Numpad;
