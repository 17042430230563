import React from 'react'

class SummaryImage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      src: ''
    };

    this.digitExists = this.digitExists.bind(this);
  }

  componentDidMount () {
    var canvas = document.createElement('canvas');
    canvas.width = 300;
    canvas.height = 400;
    var ctx = canvas.getContext('2d');

    ctx.fillStyle = '#002b36';
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.strokeStyle = '#fff';
    ctx.lineWidth = 5;
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.stroke();

    // logo
    ctx.font = '60px Chlorinar';
    ctx.fillStyle = '#4444ff';
    ctx.fillText('Compardle', 24, 76);
    ctx.fillStyle = '#ff5733';
    ctx.fillText('Compardle', 18, 70);

    this.props.guesses.forEach((guess, i) => {
      var guessString = '';
      guess.digits.forEach(digit => guessString += digit);
      var sign = '';
      if (guessString !== '') {
        if (this.props.answer < guessString) {
          sign = '<';
          ctx.fillStyle = '#cb4b16';
        }
        else if (this.props.answer > guessString) {
          sign = '>';
          ctx.fillStyle = '#268bd2';
        }
        else {
          sign = '=';
          ctx.fillStyle = '#073642';
        }
        ctx.fillRect(10, 98 + i * 60 + 8, 40, 40);
        ctx.fillStyle = '#fff';
        ctx.font = '900 50px Quicksand';
        ctx.fillText(sign, 16, 98 + i * 60 + 44);
      }

      guess.digits.forEach((digit, j) => {
        if (digit === this.props.answer.charAt(j)) {
          ctx.fillStyle = '#17d794';
        }
        else if (this.digitExists(digit, j, guess.digits)) {
          ctx.fillStyle = '#c69a00';
        }
        else {
          ctx.fillStyle = '#777';
        }

        // draw rectangle
        ctx.fillRect(58 + j * 60, 98 + i * 60, 56, 56);
      });
    });

    var src = canvas.toDataURL();
    this.setState({
      src: src
    });

    canvas.toBlob(blob => {
      this.props.onBlob(blob);
    });
  }
  
  digitExists (digit, digitIndex, guessDigits) {
    var exists = false;
    var numOccurrences = 0;
    var numCorrect = 0;
    var numExistsPrev = 0;

    if (digit !== '') {
      for (var i = 0; i < this.props.answer.length; i++) {
        if (digit === this.props.answer[i]) {
          numOccurrences++
        }

        if (digit === guessDigits[i]) {
          if (digit === this.props.answer[i]) {
            numCorrect++;
          }
          else if (i < digitIndex) {
            numExistsPrev++;
          }
        }
      }

      if (numCorrect + numExistsPrev < numOccurrences) {
        exists = true;
      }
    }

    return exists;
  }

  render () {
    return (
      <div className="SummaryImage">
        <img className="img" src={this.state.src} alt="Compardle results"/>
      </div>
    );
  }
}

export default SummaryImage;
