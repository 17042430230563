import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './font/Quicksand/Quicksand.ttf'
import './font/Chlorinar/Chlorinar.ttf'

var renderDOM = () => {
  ReactDOM.render(
    <React.StrictMode>
      <App/>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

if (window.cordova) {
  document.addEventListener(
    'deviceready',
    () => {
      renderDOM();
    },
    false
  );
}
else {
  renderDOM();
}
