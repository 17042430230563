import React from 'react'

class PopMessage extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    return (
      <div className={'PopMessage ' +
        (this.props.visible
          ? ''
          : 'hidden'
        )
      }>
        {this.props.message}
      </div>
    );
  }
}

export default PopMessage;
